import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { renderToString } from "react-dom/server";

// assets
import "../../assets/styles/shared/show-more.scss";


function ShowMore({ children, lines = 3, more = "See more", less = "See less" }) {
  const [isShowMore, setIsShowMore] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isClamped, setIsClamped] = useState(false);

  useEffect(() => setIsMounted(true), []);

  const handleReflow = ({ clamped = false }) => {
    setIsClamped(clamped);
  }

  const handleShowMoreToggle = () => {
    isClamped && setIsShowMore(!isShowMore)
  }

  return (
    <div className="lant-show-more" style={{ maxHeight: !isMounted ? "50px" : "none" }}>
      {!isShowMore ? (
        <HTMLEllipsis
          unsafeHTML={renderToString(children)}
          ellipsis="..."
          maxLine={lines}
          basedOn="letters"
          onReflow={handleReflow}
        />
      ) : (
        children
      )}
      <span
        className={`lant-show-more__control${isClamped ? "" : " lant-show-more__transparent"}`}
        onClick={handleShowMoreToggle}
      >
        {isShowMore ? less : more}
      </span>
    </div>
  );
}

ShowMore.propTypes = {
  children: PropTypes.node,
  lines: PropTypes.number,
  more: PropTypes.string,
  less: PropTypes.string,
}

export default ShowMore;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";


function MostPopularCategory({ category }) {
  let imageUrl = `${process.env.REACT_APP_IMAGES_URL}storefront_images/gilt-mirror.jpg`;
  if (category.image) {
    imageUrl = category.image.url;
  }

  return (
    <div className="home--category-block-container--block" data-testid="home-category-block-container-block">
      <Link to={`/${category.url}`} title={category.title} data-testid="home-category-block-container-block-link">
        <img
          className="home--category-image"
          src={imageUrl}
          alt={category.altTitle}
          title={category.altTitle}
          width={213}
          height={188}
        />
      </Link>
      <p className="home--category-block-container--block--title">{category.title}</p>
    </div>
  )
}

MostPopularCategory.propTypes = {
  category: PropTypes.object.isRequired,
};

export default MostPopularCategory;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// components
import MostPopularCategory from "./components/MostPopularCategory";
import ItemBlock from "../shared/ItemBlock";
import ItemBlockSkeleton from "../shared/ItemBlockSkeleton";
import SubscribeNewsletter from "../shared/SubscribeNewsletter";
import SidebarItems from "../shared/SidebarItems";
import HomeMobileIconContainer from "../shared/HomeMobileIconContainer";
import LargeAudienceIcon from "../Icons/LargeAudienceIcon";
import DataLayer from "../shared/DataLayer";
import ShowMore from "../shared/ShowMore";

// icons
import DiamondIcon from "../Icons/DiamondIcon";
import ChatsIcon from "../Icons/ChatsIcon";
import SecureIcon from "../Icons/SecureIcon";
import ReliableIcon from "../Icons/ReliableIcon";
import WorldWideIcon from "../Icons/WorldWideIcon";
import BullhornIcon from "../Icons/BullhornIcon";
import EyeIcon from "../Icons/EyeIcon";
import UserPlus from "../Icons/UserPlus";

// redux
import {
  requestHomeItems,
  getAllHomeBlogsRequest,
} from "../../redux/home/homeActions";
import { getTopPeriods } from "../../redux/periods/periodsSelector";

// hooks
import useServerSafeEffect from "../../hooks/useServerSafeEffect";

// helpers
import { HOME_SCHEMA_MARKUP_SCRIPT } from "../../helpers";
import { allCategory, HOME_FAQ_LIST, KNOWLEDGE_LINKS } from "./constants";

// assets
import "../../assets/styles/components/home.scss";

const HOME_PAGE_URL = "/profile/home";
const ITEMS_PAGE_SIZE = 16;
const META_DESCRIPTION = `
  Thousands of genuine antiques for sale, direct from trusted antique dealers.
  Furniture, Jewellery, Silver, Glass, Ceramics, Architectural Antiques, and more.
`;

function Home(props) {
  const { items, itemsLoading, categories, periods, isAuthenticated } = props;

  const [readMore, setReadMore] = useState([]);

  // to show proper Jewellery category both on staging and production
  const categoriesToRender = categories.map((category) => {
    if (category.title === "Jewellery") {
      return { ...category, url: "vintage-jewellery" };
    }

    return category;
  });

  // Methods
  useServerSafeEffect(() => {
    if (items.length) {
      return;
    }

    props.getHomeItems(1, ITEMS_PAGE_SIZE, true, true);
  }, []);

  useEffect(() => {
    const scriptToAppend = document.createElement("script");
    const homeSchemaScript = document.getElementById("home-schema-script");

    if (homeSchemaScript) {
      return;
    }

    scriptToAppend.type = "application/ld+json";
    scriptToAppend.text = HOME_SCHEMA_MARKUP_SCRIPT;
    document.body.appendChild(scriptToAppend);

    return () => {
      document.body.removeChild(scriptToAppend);
    };
  }, []);

  const onReadMore = (key) => () => {
    setReadMore([...readMore, key]);
  };

  const returnKnowledgeLinksSection = () => {
    return (
      <section className="home--block" data-testid="home-knowledge-links">
        <div className="page-container">
          <Row gutter={[30, 30]}>
            {KNOWLEDGE_LINKS.map((item, index) => (
              <Col
                md={12}
                xs={24}
                key={`faq-${index}`}
                data-testid="lant-knowledge"
              >
                <h2 className="h2" data-testid="lant-knowledge__title">
                  {item.title}
                </h2>
                <div
                  className="home--text"
                  data-testid="lant-knowledge__description"
                >
                  {item.description}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    );
  };

  return (
    <div className="home">
      <DataLayer pageTitle="Antiques For Sale UK - Buy & Sell Antiques Online" />
      <Helmet>
        <title>
          Antiques For Sale UK - Buy & Sell Antiques Online
        </title>
        <meta name="description" content={META_DESCRIPTION} />
        <meta name="robots" content="all" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_URL}${props.location.pathname}`}
        />
      </Helmet>

      <section className="home--recommended">
        <div className="home--recommended-container page-container">
          <h1 className="home--recommended-container--heading">
            Buy genuine antiques online from trusted UK antique dealers
          </h1>

          <p className="home--recommended-container--subheading">
            Browse <span className="color-red">30,000+</span> antiques from over{" "}
            <span className="color-red">400</span> verified dealers
          </p>

          <div
            className="home--category-block-container"
            data-testid="home-category-block-container"
          >
            {allCategory.map((category) => category ? (
              <MostPopularCategory key={category.id} category={category} />
            ) : null
            )}
          </div>
        </div>
      </section>
      <div className="page-container">
        <Row gutter={[30, 30]} className="home--body-container">
          <Col xs={0} xl={6}>
            <SidebarItems
              title="Antique Categories"
              items={categoriesToRender}
              linkTo="category"
              data-testid="home-sidebar-categories"
              firstRow={
                <div className="home-sidebar--row">
                  <Link to="/antiques" className="home-sidebar--row--link">
                    All Categories
                  </Link>
                </div>
              }
            />

            <SidebarItems
              title="Antique Period"
              items={periods}
              linkTo="period"
              data-testid="home-sidebar-periods"
              lastRow={
                <Link to="/period" className="home-sidebar--row">
                  View All Periods
                </Link>
              }
            />

            <SubscribeNewsletter data-testid="home-sidebar-subscribe-for-new-letters" />
          </Col>

          <Col xl={18} sm={24} className="home--items-block-container">
            <section
              className="home--items"
              data-testid="home-items-block-container"
            >
              <h2 className="h2">Latest Items from our Antiques Store</h2>

              <Row gutter={[30, 30]} className="home--items-container">
                {itemsLoading ? (
                  <ItemBlockSkeleton amount={16} />
                ) : (
                  items.map((item) => <ItemBlock key={item.id} item={item} />)
                )}
              </Row>

              <Link
                to="/antiques"
                className="home--button-to-antiques"
                data-testid="home-button-to-antiques"
              >
                <Button size="middle">
                  Click to see more recently added antiques...
                </Button>
              </Link>
            </section>
          </Col>
        </Row>
      </div>

      <Col xs={0} sm={24}>
        {returnKnowledgeLinksSection()}
      </Col>

      <section className="home--join" data-testid="home-join-section">
        <div className="page-container">
          <p className="home--join-title">
            Join
            <br />
            <span className="highlight">Love</span>Antiques
            <span className="highlight">.com</span>
          </p>

          <p className="home--info">
            LoveAntiques.com is perfect for both collectors and antique dealers
            in the UK to buy and sell a wide assortment of different rare
            antiques. There are many benefits from using our website for both
            collectors and dealers:
          </p>

          <Row gutter={[30, 30]}>
            <Col xs={24} sm={12} md={12} xl={6}>
              <h2 className="h2 home--join-info">
                As a <span className="highlight">Collector</span> - Why join and
                how to buy antiques online on LoveAntiques.com?
              </h2>

              <Col xs={0} sm={24} className="p-0">
                <div>
                  <ShowMore
                    lines={6}
                    more="Read more"
                    className="read-more-text"
                    less="Read less"
                  >
                    One of the most significant benefits of buying from
                    LoveAntiques.com for a collector is that we have one of the
                    largest selections of antiques for sale, UK wide, whether
                    you are looking for antiques in London, or anywhere else
                    throughout the country and even around the world. You can
                    contact the dealers directly and ask any questions that you
                    may have about their products, as well as pay for your
                    purchases securely using either card payments, or PayPal.
                    With every antique dealer being scrutinised before they join
                    LoveAntiques.com, you can also rest assured that you are
                    dealing with reputable, and reliable antique dealers.
                  </ShowMore>

                  <div className="home--join-button-container">
                    <Link
                      to={
                        !isAuthenticated
                          ? "/registration/collector"
                          : HOME_PAGE_URL
                      }
                    >
                      <Button className="home--join-button" size="large">
                        Join as Collector
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={0}>
                <div className="home--join--mobile-container">
                  <HomeMobileIconContainer
                    iconNode={<DiamondIcon />}
                    iconTitle="Largest Antique Collection"
                  />
                  <HomeMobileIconContainer
                    iconNode={<ChatsIcon />}
                    iconTitle="Easy to contact dealer"
                  />
                  <HomeMobileIconContainer
                    iconNode={<SecureIcon />}
                    iconTitle="Secure Payments"
                  />
                  <HomeMobileIconContainer
                    iconNode={<ReliableIcon />}
                    iconTitle="Reliable antique dealers"
                  />
                </div>
                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated
                        ? "/registration/collector"
                        : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Join as Collector
                    </Button>
                  </Link>
                </div>
              </Col>
            </Col>

            <Col xs={24} sm={12} md={12} xl={6}>
              <h2 className="h2 home--join-info">
                As a <span className="highlight">Dealer</span> - Why join and
                how to list your antiques for sale online in UK
              </h2>

              <Col xs={0} sm={24} className="p-0">
                <div>
                  <ShowMore
                    lines={6}
                    more="Read more"
                    className="read-more-text"
                    less="Read less"
                  >
                    If you are a dealer and have items that are vintage,
                    antique, or collectable for sale, LoveAntiques.com is the
                    perfect platform for you to sell your products on a
                    worldwide scale, helping you to grow your business. Once you
                    have created your account and it has been approved, you
                    upload your items for sale providing detailed descriptions.
                    We will then optimise the pages your products appear on for
                    SEO purposes, and your stock will instantly be in front of
                    thousands of people. Potential buyers can contact you
                    directly if they have any questions, and all payments go
                    directly to you. If you are looking to grow your business,
                    LoveAntiques.com is the solution that can help drive sales
                    by getting your products in front of a much larger audience.
                  </ShowMore>

                  <div className="home--join-button-container">
                    <Link
                      to={
                        !isAuthenticated
                          ? "/registration/dealers"
                          : HOME_PAGE_URL
                      }
                    >
                      <Button className="home--join-button" size="large">
                        Join as Dealer
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={0}>
                <div className="home--join--mobile-container">
                  <HomeMobileIconContainer
                    iconNode={<WorldWideIcon />}
                    iconTitle="Worldwide scale"
                  />
                  <HomeMobileIconContainer
                    iconNode={<ChatsIcon />}
                    iconTitle="Easy to contact buyers"
                  />
                  <HomeMobileIconContainer
                    iconNode={<SecureIcon />}
                    iconTitle="Secure Payments"
                  />
                  <HomeMobileIconContainer
                    iconNode={<LargeAudienceIcon />}
                    iconTitle="Large audience"
                  />
                </div>
                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated ? "/registration/dealers" : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Join as Dealer
                    </Button>
                  </Link>
                </div>
              </Col>
            </Col>

            <Col xs={24} sm={12} md={12} xl={6}>
              <h2 className="h2 home--join-info">
                As an <span className="highlight">Antiques Business</span> - Why
                register and how to promote your business on LoveAntiques.com?
              </h2>

              <Col xs={0} sm={24} className="p-0">
                <div>
                  <ShowMore
                    lines={6}
                    more="Read more"
                    className="read-more-text"
                    less="Read less"
                  >
                    Registering your antiques business with LoveAntiques.com
                    allows you to reach a broader audience and gain visibility
                    among thousands of potential customers. Whether you choose
                    the free or premium listing option, your business details
                    will be prominently displayed in our directory, making it
                    easier for antique enthusiasts to find and contact you. Our
                    platform provides an excellent opportunity to showcase your
                    unique offerings, from antique furniture to vintage
                    collectibles, and connect with a community of passionate
                    buyers and sellers. By joining LoveAntiques.com, you can
                    effectively promote your business, drive traffic to your
                    website, and increase your sales.
                  </ShowMore>

                  <div className="home--join-button-container">
                    <Link
                      to={
                        !isAuthenticated
                          ? "/registration/dealers"
                          : HOME_PAGE_URL
                      }
                    >
                      <Button className="home--join-button" size="large">
                        Register your Business
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={0}>
                <div className="home--join--mobile-container">
                  <HomeMobileIconContainer
                    iconNode={<BullhornIcon />}
                    iconTitle="Promote your business"
                  />
                  <HomeMobileIconContainer
                    iconNode={<EyeIcon />}
                    iconTitle="Increase visibility"
                  />
                  <HomeMobileIconContainer
                    iconNode={<SecureIcon />}
                    iconTitle="Secure Payments"
                  />
                  <HomeMobileIconContainer
                    iconNode={<UserPlus />}
                    iconTitle="Connect with customers"
                  />
                </div>
                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated ? "/registration/trader" : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Register your Business
                    </Button>
                  </Link>
                </div>
              </Col>
            </Col>

            <Col xs={24} sm={12} md={12} xl={6}>
              <p className="h2 home--join-info">More Information?</p>

              <div style={{ marginBottom: "20px" }}>
                <ShowMore
                  lines={6}
                  more="Read more"
                  className="read-more-text"
                  less="Read less"
                >
                  If you want to know more about how the site works, or how to
                  join as a collector or a dealer, send us an email to
                  info@loveantiques.com, or use our online contact form. If you
                  love to buy or sell antiques, then LoveAntiques.com is the
                  place for you!
                </ShowMore>

                <div className="flex home--join-button-container">
                  <Link to="/contact-us">
                    <Button className="home--join-button" size="large">
                      Ask a Question
                    </Button>
                  </Link>

                  <Link to="/faq">
                    <Button
                      className="home--join-button home--join-button_right"
                      size="large"
                      ghost
                    >
                      View FAQ
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <Col xs={24} sm={0}>
        {returnKnowledgeLinksSection()}
      </Col>

      <section className="home--block" data-testid="home-faq-container">
        <div className="home--faq-container">
          <p className="h2">BUYING ANTIQUES ONLINE FAQs</p>
          {HOME_FAQ_LIST.map((item, index) => (
            <React.Fragment key={`HOME_FAQ_LIST-${index}`}>
              <h3 className="h3" data-testid="lant-faq-title">
                {`Q: ${item.title}`}
              </h3>
              <div
                className="static-page--content-block-text"
                data-testid="lant-faq-description"
              >
                {item.description}
              </div>
            </React.Fragment>
          ))}
        </div>
      </section>
    </div>
  );
}

Home.propTypes = {
  items: PropTypes.array.isRequired,
  itemsLoading: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  periods: PropTypes.array.isRequired,
  getHomeItems: PropTypes.func.isRequired,
  getHomeBlogs: PropTypes.func.isRequired,
  blogArticles: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    items: state.home.items,
    itemsLoading: state.home.itemsLoading,
    categories: state.home.allCategories,
    periods: getTopPeriods(state),
    blogArticles: state.home.homeBlogs,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  {
    getHomeItems: requestHomeItems,
    getHomeBlogs: getAllHomeBlogsRequest,
  }
)(Home);
